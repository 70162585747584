<h2 *ngIf="!!header" [ngClass]="(isMobile$ | async) ? 'smaller-h2' : null">
  {{ header | translate }}
</h2>
<div class="actions">
  <div class="actions__body">
    <contact-actions-widget-list-item
      *ngFor="let action of actions$ | async"
      [action]="action"
      (delete)="onDelete.emit($event)"
      (noteUpdated)="noteUpdated.emit($event)"
    >
    </contact-actions-widget-list-item>
  </div>
  <list-item-empty *ngIf="(actions$ | async)?.length === 0">{{
    "no_hits" | translate
  }}</list-item-empty>
  <list-load-more
    *ngIf="actionsLeftToLoad$ | async"
    [disabled]="loading$ | async"
    (loadMore)="onLoadMore()"
    [leftToLoad]="actionsLeftToLoad$ | async"
    size="large"
  >
  </list-load-more>
</div>
