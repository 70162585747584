<div
  bsModal
  class="modal fade"
  #modal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: false }"
  (onHidden)="form.reset()"
>
  <div class="modal-dialog" *ngIf="contact">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ "contact_info_from_eniro" | translate }}
        </h4>
        <button type="button" class="close pull-right" (click)="modal.hide()">
          <span>&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="alert alert-info">
          {{ "following_info_found_eniro" | translate }}
        </div>
        <div *ngIf="type === 'Business'" class="alert alert-warning">
          {{ "this_is_a_company" | translate }}
        </div>

        <form novalidate #form="ngForm">
          <div *ngIf="contact.firstName" class="checkbox">
            <label>
              <input
                type="checkbox"
                name="firstName"
                [(ngModel)]="vm.firstName"
              />
              {{ contact.firstName }}
              <span class="text-muted">({{ "firstName" | translate }})</span>
            </label>
          </div>

          <div *ngIf="contact.familyName" class="checkbox">
            <label>
              <input
                type="checkbox"
                name="familyName"
                [(ngModel)]="vm.familyName"
              />
              {{ contact.familyName }}
              <span class="text-muted">({{ "lastName" | translate }})</span>
            </label>
          </div>

          <div *ngIf="contact.msisdn" class="checkbox">
            <label>
              <input type="checkbox" name="msisdn" [(ngModel)]="vm.msisdn" />
              {{ contact.msisdn }}
              <span class="text-muted">({{ "mobile" | translate }})</span>
            </label>
          </div>

          <div *ngIf="contact.phoneNumber" class="checkbox">
            <label>
              <input
                type="checkbox"
                name="phoneNumber"
                [(ngModel)]="vm.phoneNumber"
              />
              {{ contact.phoneNumber }}
              <span class="text-muted">({{ "phone" | translate }})</span>
            </label>
          </div>

          <div *ngIf="contact.street" class="checkbox">
            <label>
              <input type="checkbox" name="street" [(ngModel)]="vm.street" />
              {{ contact.street }}
              <span class="text-muted">({{ "address" | translate }})</span>
            </label>
          </div>

          <div *ngIf="contact.zip" class="checkbox">
            <label>
              <input type="checkbox" name="zip" [(ngModel)]="vm.zip" />
              {{ contact.zip }}
              <span class="text-muted">({{ "zip" | translate }})</span>
            </label>
          </div>

          <div *ngIf="contact.city" class="checkbox">
            <label>
              <input type="checkbox" name="city" [(ngModel)]="vm.city" />
              {{ contact.city }}
              <span class="text-muted">({{ "city" | translate }})</span>
            </label>
          </div>
        </form>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default btn-lg cancel"
          (click)="modal.hide()"
        >
          {{ "cancel" | translate }}
        </button>
        <button
          type="submit"
          class="btn btn-success btn-lg submit"
          (click)="save()"
        >
          {{ "save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
