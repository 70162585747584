<div class="table-wrapper">
  <div class="header">
    <img [src]="icon" alt="" />
    <h3>
      {{ title | translate }}
    </h3>
  </div>
  <div class="table-body-container">
    <div *ngIf="loading; else dataTable" class="loading">
      <spinner [inlineBlock]="true" [size]="'1.2rem'"></spinner>
    </div>
    <ng-template #dataTable>
      <table class="table table--striped">
        <tbody *ngIf="list?.results && list?.results?.rows">
          <ng-container *ngFor="let result of list.results.rows; let i = index">
            <tr *ngIf="(list.summary && i < 10) || !list.summary">
              <td [class.highlighted]="focusEntityId === result.id">
                <div class="cell-container">
                  <div class="name-span">
                    <div class="cell-divider">{{ result.position }}.</div>
                    <span
                      class="truncate"
                      [title]="
                        !isOffice
                          ? result.name
                          : (result.name | removeOfficePrefix | async)
                      "
                    >
                      {{
                        !isOffice
                          ? result.name
                          : (result.name | removeOfficePrefix | async)
                      }}
                    </span>
                  </div>
                  <ng-container
                    *ngIf="list.type.includes('percentage'); else normalValue"
                    [class.highlighted]="focusEntityId === result.id"
                  >
                    <span class="truncate" [title]="round(result.value, 1)">
                      {{ round(result.value, 1) }}
                    </span>
                  </ng-container>
                  <ng-template #normalValue>
                    <span
                      class="truncate"
                      [title]="round(result.value, 0) | qNumber"
                    >
                      {{ getMeasurementUnit(list.type) }}
                      {{ round(result.value, 0) | qNumber }}
                    </span>
                  </ng-template>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="list?.results && list?.results?.rows?.length === 0">
            <td colspan="3" class="list-empty" translate>
              no_data_available_for_list
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
</div>
