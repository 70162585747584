<table class="table table--striped">
  <thead>
    <tr>
      <th translate>address</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
      <tr
        (click)="navigate(resource.eaOid)"
        *ngFor="let resource of resources$ | async"
      >
        <td dataLabel="address">
          {{ resource.street }}
        </td>
      </tr>
    </ng-container>

    <ng-template #empty>
      <tr>
        <td class="text-center" colspan="100%" translate>no_objects</td>
      </tr>
    </ng-template>
  </tbody>
</table>
