<div [formGroup]="formGroup">
  <section>
    <app-assign-lead-buttons
      *ngIf="
        (advancedLeadsHandlingFeature$ | async).enabled;
        else employeeDropdown
      "
      [eaEmployeeId]="eaEmployeeId"
      [selectedEaTaskIds]="[eaTaskId]"
    >
    </app-assign-lead-buttons>

    <ng-template #employeeDropdown>
      <div class="form-group">
        <select formControlName="office">
          <option disabled value="" selected>
            {{ "select_office" | translate }}
          </option>
          <option *ngFor="let office of offices" [ngValue]="office">
            {{ office.name | removeOfficePrefix | async }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <select formControlName="employee">
          <option disabled value="" selected>
            {{ "select_broker" | translate }}
          </option>
          <option value="null">{{ "free_office_lead" | translate }}</option>
          <option
            *ngFor="let employee of employees"
            [value]="employee.eaEmployeeId"
          >
            {{ employee.fullName }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>
          <input
            formControlName="sendMessage"
            class="checkbox checkbox-inline"
            type="checkbox"
          />
          {{ "send_message_when_lead_is_created" | translate }}
        </label>
        <textarea-counter
          *ngIf="formGroup.get('sendMessage').value"
          [maxLength]="1250"
          [minHeight]="'10rem'"
          [parentFormGroup]="formGroup"
          parentFormControlName="message"
        ></textarea-counter>
      </div>
    </ng-template>
  </section>
</div>
