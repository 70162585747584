import * as widgetActions from "@app/widgets/widgets/statistics-top-list/ngrx/statistics-top-list-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";
import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";

const defaultTopList: TopList = {
  type: "",
  results: null,
  loading: false,
  summary: false,
  averageValue: 0,
  totalValue: 0,
};

export interface TopListsState {
  loading: boolean;
  estatecommision: TopList;
  soldobjects: TopList;
  salecontract: TopList;
  banktips: TopList;
  npsaveragevalue: TopList;
  commissionpercentage: TopList;
}

export const initialState: TopListsState = {
  loading: false,
  estatecommision: defaultTopList,
  soldobjects: defaultTopList,
  salecontract: defaultTopList,
  banktips: defaultTopList,
  npsaveragevalue: defaultTopList,
  commissionpercentage: defaultTopList,
};

export function topListsReducer(
  state: TopListsState,
  action: Action
): TopListsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(widgetActions.fetchTopListsRequest, (state, { listType }) => ({
    ...state,
    loading: true,
    [listType]: state[listType] ? { ...state[listType], loading: true } : null,
  })),
  on(
    widgetActions.fetchTopListsSuccess,
    (state, { listType, summary, topList }) => ({
      ...state,
      [listType]: {
        loading: false,
        summary,
        ...topList,
        type: listType,
      },
      loading: false,
    })
  ),
  on(widgetActions.fetchTopListsFail, (state, { listType }) => ({
    ...state,
    loading: false,
    [listType]: {
      ...state[listType],
      loading: false,
      summary: true,
    },
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.topLists
);

export const getTopListByType = (type: string) =>
  createSelector(getState, (state) => state[type] as TopList);

export const getLoading = createSelector(
  getState,
  (state: TopListsState) => state.loading
);
