<div dropdown class="timefield-dropdown">
  <div class="input-iconfield">
    <input
      #input
      dropdownToggle
      class="dropdown-toggle"
      placeholder="{{ label | translate }}"
      (input)="propagateChange($event.target.value)"
      (blur)="propagateTouch()"
    />
    <i class="icon icon-clock"></i>
  </div>
  <ul *dropdownMenu class="dropdown-menu" role="menu">
    <li
      *ngFor="let time of times"
      (click)="handleTimeSelected(time)"
      class="dropdown-item timefield-dropdown__item"
    >
      {{ time }}
    </li>
  </ul>
</div>
