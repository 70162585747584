<quill-editor
  #quill
  [sanitize]="true"
  [placeholder]="placeholder"
  [modules]="quillConfiguration"
  [format]="toolbarEnabled ? 'html' : 'text'"
  [styles]="styles"
  [formControl]="control"
  (onEditorChanged)="setCaretPosition($event)"
  (onEditorCreated)="editorCreated($event)"
>
</quill-editor>
