<div class="wrapper">
  <sidebar-header
    [label]="'mitt_bolan' | translate"
    [type]="tabType"
  ></sidebar-header>

  <div *ngIf="task$ | async as task" class="scroll-view">
    <sidebar-tasks-detail-card [task]="task"></sidebar-tasks-detail-card>
    <hr />
    <section>
      <div class="alert alert-info">
        {{ "unmanageable_use_crm1" | translate }}
      </div>
    </section>
  </div>
</div>
