<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'create_lead_result_option' | translate"
  >
  </sidebar-header>
  <div class="scroll-view">
    <app-lead-result-options-form></app-lead-result-options-form>
  </div>
  <div class="sidebar-footer">
    <button
      tabindex="-1"
      type="button"
      class="btn btn-cancel btn-lg"
      (click)="closeTab()"
    >
      {{ "cancel" | translate }}
    </button>
    <button type="button" class="btn btn-success btn-lg" (click)="onSubmit()">
      {{ "save" | translate }}
    </button>
  </div>
</div>
