<ng-container *ngIf="type !== 'nps'">
  <a
    *ifRole="roles"
    queryParamsHandling="preserve"
    [routerLink]="[
      '/crm',
      {
        outlets: {
          modal: ['kpi', type$ | async, 'offices', eaOfficeId$ | async]
        }
      }
    ]"
    class="btn btn-default btn-lg back"
  >
    {{ "back" | translate }}
  </a>
</ng-container>

<div class="table-container">
  <ng-template #container></ng-template>
</div>
