<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'edit_search_profile' | translate"
  ></sidebar-header>
  <div class="scroll-view" *ngIf="!(loading$ | async); else contentLoader">
    <section>
      <app-search-profile-form
        [additionalFilters]="additionalFilters$ | async"
        [selectedAreas]="searchProfileAreas$ | async"
        [editMode]="true"
        [searchProfile]="searchProfile$ | async"
        [observer]="proxy$"
        [currency]="currency$ | async"
        (submit)="updateSearchProfile($event)"
      ></app-search-profile-form>
    </section>
  </div>
  <div class="sidebar-footer">
    <app-q-button (click)="closeTab()" buttonType="cancel" label="cancel">
    </app-q-button>
    <app-q-button
      *ngIf="searchProfile$ | async as searchProfile"
      [isLoading]="deleting$ | async"
      (click)="deleteSearchProfile(searchProfile.searchProfileId)"
      buttonType="remove"
      label="remove"
    >
    </app-q-button>
    <app-q-button
      [isLoading]="patching$ | async"
      (click)="submit()"
      buttonType="save"
      label="save"
    >
    </app-q-button>
  </div>
</div>

<ng-template #contentLoader>
  <section>
    <app-skeleton-sidebar></app-skeleton-sidebar>
  </section>
</ng-template>
