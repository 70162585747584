<div *ngIf="validation$ | async as validation">
  <div *ngIf="validation.suggestions.length > 0 && !hidden">
    <small [@fadeInOut] class="help-text">
      {{ "address_not_found_suggestions" | translate }}
    </small>

    <ul class="list-group">
      <li
        [@fadeInOut]
        *ngFor="let suggestion of validation.suggestions"
        (click)="handleClick(suggestion)"
        class="list-group-item"
      >
        {{ formatAddress(suggestion) }}
      </li>
    </ul>
  </div>
</div>
