<h3>
  <app-icon name="network-wired" size="large"></app-icon>&nbsp;<span translate
    >era_intranet</span
  >
</h3>
<div class="widget-container">
  <ng-container *ngIf="eraIntranetUrl$ | async as eraIntranetUrl; else noData">
    <a (click)="openLink(eraIntranetUrl)" translate>go_to_era_intranet</a>
    <p></p>
  </ng-container>
  <ng-template #noData>
    <div class="no-data">
      <span translate>no_data_found</span>
    </div>
  </ng-template>
</div>
