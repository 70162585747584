<div [ngxErrors]="name">
  <div
    *ngFor="let error of errors"
    [ngxError]="error.name"
    [showWhen]="error.when"
    class="q-control-error"
  >
    {{ error.text | translate }}
  </div>
</div>
