<div class="q-search-list-item">
  <h5 class="q-search-list-item__title">{{ title }}</h5>
  <div class="q-search-list-item__body">
    <p class="q-search-list-item__secondary-text">
      {{ joinArray(secondaryText) }}
    </p>
    <p
      *ngIf="!!chipData"
      class="q-search-list-item__chip"
      [class]="'q-chip--' + chipData.color"
    >
      {{ chipData.text }}
    </p>
  </div>
</div>
