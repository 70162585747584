<table class="table table--striped">
  <thead>
    <tr>
      <th translate>street</th>
      <th translate>city</th>
      <th translate>date</th>
      <th translate>commission</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
      <tr *ngFor="let resource of resources$ | async">
        <td dataLabel="street">
          <a (click)="navigateToObject(resource.eaOid, $event)" href="#">{{
            resource.street
          }}</a>
        </td>
        <td dataLabel="city">{{ resource.city }}, {{ resource.zip }}</td>
        <td dataLabel="date">
          {{ formatDate(resource.rentalDate) | momentify }}
        </td>
        <td dataLabel="commissions_earnings">
          {{ resource.commission | qNumber : "1.0-0" }}
        </td>
      </tr>
    </ng-container>

    <ng-template #empty>
      <tr>
        <td class="text-center" colspan="100%" translate>
          no_commissions_earnings
        </td>
      </tr>
    </ng-template>
  </tbody>
</table>
