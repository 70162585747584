<div class="calling-list-card-details">
  <div class="calling-list-info" *ngIf="callingList; else loading">
    <div class="name-wrapper">
      <section class="calling-list-card-details__list-info">
        <h1 class="name">
          <b>{{ "calling_list" | translate }}:</b> {{ callingList.title }}
        </h1>
      </section>
    </div>
    <section class="calling-list-card-details__list-contact-info">
      <p><i class="fa fa-building"></i>{{ callingList.officeName }}</p>
      <p><i class="icon-user"></i>{{ callingList.employeeFullName }}</p>
    </section>

    <hr />

    <section class="calling-list-card-details__progress">
      <h5 class="calling-list-card-details__progress__text">
        {{ callingList?.progress?.contacted }}/{{
          callingList?.progress?.contacts
        }}
        {{ "calling_list_contacted" | translate }}
      </h5>

      <div class="calling-list-card-details__progress__bar">
        <progressbar
          [max]="callingList?.progress?.contacts"
          [value]="callingList?.progress?.contacted"
          [type]="
            getProgressType(
              callingList?.progress?.contacts,
              callingList?.progress?.contacted
            )
          "
        >
        </progressbar>
      </div>
    </section>
  </div>
</div>

<ng-template #loading>
  <div class="wrapper">
    <div class="contact-info">
      <skeleton-rectangle [height]="1.5"></skeleton-rectangle>
      <skeleton-rectangle [height]="1" [width]="12"></skeleton-rectangle>
      <skeleton-rectangle [height]="1" [width]="12"></skeleton-rectangle>

      <hr />

      <div class="progress-wrapper">
        <div class="progress-bar-wrapper">
          <skeleton-rectangle [height]="1"></skeleton-rectangle>
        </div>
      </div>
    </div>
  </div>
</ng-template>
