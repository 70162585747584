<div class="wrapper">
  <div class="form-group">
    <ng-content selector="input, textarea"></ng-content>
    <label
      class="input-placeholder text-muted"
      [attr.for]="inputChild.id"
      [class.float]="!inputChild.empty"
      >{{ inputChild.placeholder }}</label
    >
    <ng-content></ng-content>
  </div>
</div>
