import { Component, Input } from "@angular/core";
import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";

@Component({
  selector: "app-top-lists-table",
  templateUrl: "./top-lists-table.component.html",
  styleUrls: ["./top-lists-table.component.scss"],
})
export class TopListsTableComponent {
  @Input() title: string;
  @Input() icon: string;
  @Input() list: TopList;
  @Input() focusEntityId: string;
  @Input() isOffice: boolean;
  @Input() loading: boolean;
  @Input() currency: string;

  getMeasurementUnit(topListType: string): string | void {
    switch (topListType) {
      case "estatecommission":
      case "commissionaverage":
        return this.currency;
      case "commissionpercentage":
        return "(%)";
      default:
        return "";
    }
  }

  round(num: any, precision: number): any {
    return precision > 0 ? num?.toFixed(precision) : Math.round(num);
  }
}
