<div [formGroup]="parentFormGroup">
  <textarea
    (input)="countWords($event)"
    [maxlength]="maxLength"
    [style.min-height]="minHeight"
    [formControlName]="parentFormControlName"
  ></textarea>
  <p *ngIf="!!maxLength">
    {{ currentLength }} {{ "of" | translate }} {{ maxLength }}
  </p>
</div>
