<app-q-modal>
  <ng-container slot="header">
    {{ "uploaded_file" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="modal-body">
      <section *ngIf="data?.contacts?.length > 0">
        <app-q-table
          [columns]="columns"
          [headers]="headers"
          [enableSelection]="false"
          [enableClick]="false"
          [enableActions]="false"
          [enableSearch]="false"
          [key]="'create-contacts-calling-list'"
          [enablePaginator]="true"
          [data]="paginatedContacts$ | async"
          [length]="data?.contacts?.length"
          [defaultPageSize]="paginator$.value.pageSize"
          (page)="onPage($event)"
        >
          <ng-template let-cell="cell" let-column="column" let-row="row">
            <ng-container [ngSwitch]="column">
              <ng-container *ngSwitchDefault>
                {{ cell }}
              </ng-container>
            </ng-container>
          </ng-template>
        </app-q-table>
      </section>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button
      [buttonType]="'cancel'"
      mat-dialog-close
      [label]="'close'"
      (click)="closeModal()"
    >
    </app-q-button>
  </ng-container>
</app-q-modal>
