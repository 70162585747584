<standalone-sidebar>
  <standalone-sidebar-header (onClose)="closeSidebar.emit()">
    {{ "history" | translate }}
  </standalone-sidebar-header>
  <standalone-sidebar-content>
    <section>
      <div class="widget-container" [class.loading-pulse]="loading$ | async">
        <ng-container *ngIf="objectsList$ | async as objectsList">
          <h3>{{ "latest_visited_properties" | translate }}:</h3>
          <ng-container *ngFor="let item of objectsList">
            <div class="card-container">
              <app-object-card
                [object]="item"
                [navigationEndpoint]="navigationEndpoint$ | async"
                (handleClick)="closeSidebar.emit()"
              >
              </app-object-card>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="contactsList$ | async as contactsList">
          <h3>{{ "latest_visited_contacts" | translate }}:</h3>
          <ng-container *ngFor="let item of contactsList">
            <div class="card-container">
              <app-contact-card
                [contact]="item"
                (handleClick)="closeSidebar.emit()"
              >
              </app-contact-card>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </section>
  </standalone-sidebar-content>
</standalone-sidebar>
