import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of } from "rxjs";
import { ApiService } from "@app/core/services/api/api.service";
import * as widgetActions from "@app/widgets/widgets/statistics-top-list/ngrx/statistics-top-list-widget.actions";
import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";

@Injectable()
export class StatisticsTopListWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchTopList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchTopListsRequest),
      mergeMap(({ listType, filters }) =>
        this.apiService.get(`top-list/${listType}`, filters).pipe(
          map((response: TopList) =>
            widgetActions.fetchTopListsSuccess({
              listType,
              summary: !!filters.focusEntityId,
              topList: response,
            })
          ),
          catchError(() =>
            of(
              widgetActions.fetchTopListsFail({
                listType,
              })
            )
          )
        )
      )
    )
  );
}
