<h3>
  <app-icon size="large" name="briefcase"></app-icon>&nbsp;<span>{{
    "next_step" | translate
  }}</span>
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="next-step-container">
    <next-step-list-item
      *ngFor="let task of tasks$ | async"
      [task]="task"
      [disableClick]="eaEmployeeId !== task.eaEmployeeId"
      [icon]="taskTypeResolver.resolveIcon(task) | async"
      [color]="!!task.resolved ? 'gray' : 'orange'"
    >
    </next-step-list-item>
    <list-item-empty *ngIf="(tasks$ | async)?.length === 0">
      {{ "no_tasks_to_handle" | translate }}
    </list-item-empty>
    <list-load-more
      *ngIf="remainingTasks$ | async"
      [disabled]="loading$ | async"
      (loadMore)="onLoadMore()"
      [leftToLoad]="remainingTasks$ | async"
      [size]="'large'"
    >
    </list-load-more>
  </div>
</div>
