<h4>{{ "choose_working_office" | translate }}</h4>
<section class="select-office">
  <div *ngFor="let employee of employees$ | async; let i = index">
    <h3 class="select-office__user">{{ "user" | translate }} {{ i + 1 }}</h3>
    <ul>
      <select-office-item
        *ngFor="let office of employee.offices"
        [employee]="employee"
        [office]="office"
        (onClick)="selectOfficeAndLogin($event)"
      >
      </select-office-item>
    </ul>
  </div>
</section>
