<div class="q-chip" [class]="'q-chip--' + color">
  {{ text }}
  <div class="q-chip--icon">
    <app-icon
      *ngIf="removable"
      size="small"
      name="times"
      (click)="removeClicked.emit()"
    ></app-icon>
  </div>
</div>
