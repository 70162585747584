import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, map, Observable, shareReplay, Subject } from "rxjs";
import { AppState } from "@app/app.reducer";
import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";
import { TopListFilters } from "@app/statistics/statistics-top-lists/models/top-list-filters";
import * as fromWidget from "@app/widgets/widgets/statistics-top-list/ngrx/statistics-top-list-widget.reducer";
import * as widgetActions from "@app/widgets/widgets/statistics-top-list/ngrx/statistics-top-list-widget.actions";
import moment from "moment";
import * as fromConfig from "@app/shared/config/config.reducer";

const TOP_LIST_TYPES = {
  TURNOVER: "estatecommission",
  SOLD_PROPERTIES: "soldobjects",
  CONTRACT_SIGNED: "salescontract",
  SENT_BANK_TIPS: "banktips",
  NPS: "npsaveragevalue",
  AVERAGE_COMMISSION: "commissionaverage",
} as const;

@Component({
  selector: "app-statistics-top-list-widget",
  templateUrl: "./statistics-top-list-widget.component.html",
  styleUrls: ["./statistics-top-list-widget.component.scss"],
})
export class StatisticsTopListWidgetComponent implements OnInit, OnDestroy {
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;

  topLists$: Observable<Record<string, TopList>>;
  currency$: Observable<string>;
  unsubscribe$ = new Subject<void>();

  groupFilters = [
    { label: "top_lists_filter_agent", value: "employee" },
    { label: "top_lists_filter_office", value: "office" },
  ];
  groupBy = "employee";
  dateFilters = [
    { label: "top_lists_filter_month", value: "month" },
    { label: "top_lists_filter_year", value: "year" },
  ];
  dateType = "year";
  minDate = "";
  maxDate = "";
  topListKeys = [
    TOP_LIST_TYPES.TURNOVER,
    TOP_LIST_TYPES.SOLD_PROPERTIES,
    TOP_LIST_TYPES.CONTRACT_SIGNED,
    TOP_LIST_TYPES.SENT_BANK_TIPS,
    TOP_LIST_TYPES.NPS,
    TOP_LIST_TYPES.AVERAGE_COMMISSION,
  ];
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initializeDateRange();
    this.getStatistics();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  changeGroupFilter(filter: string) {
    this.groupBy = filter;
    this.getStatistics();
  }

  changeDateFilter(dateType: string) {
    this.dateType = dateType;
    this.initializeDateRange();
    this.getStatistics();
  }

  getTypeIcon(type: string) {
    switch (type) {
      case TOP_LIST_TYPES.TURNOVER:
        return "/assets/img/turnover.svg";
      case TOP_LIST_TYPES.SOLD_PROPERTIES:
        return "/assets/img/sold_properties.svg";
      case TOP_LIST_TYPES.CONTRACT_SIGNED:
        return "/assets/img/contract_signed.svg";
      case TOP_LIST_TYPES.SENT_BANK_TIPS:
        return "/assets/img/sent_bank_tips.svg";
      case TOP_LIST_TYPES.NPS:
        return "/assets/img/nps.svg";
      case TOP_LIST_TYPES.AVERAGE_COMMISSION:
        return "/assets/img/average_commission.svg";
      default:
        return "";
    }
  }

  getTypeTitle(type: string) {
    switch (type) {
      case TOP_LIST_TYPES.TURNOVER:
        return "turnover";
      case TOP_LIST_TYPES.NPS:
        return "nps";
      default:
        return type;
    }
  }

  private mapStateToProps() {
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));

    this.topLists$ = combineLatest(
      Object.values(TOP_LIST_TYPES).map((type) =>
        this.store.select(fromWidget.getTopListByType(type)).pipe(
          map((topList) => {
            if (!topList || !topList?.results?.rows?.length) {
              return { [type]: topList };
            }

            const focusEntityId = this.getFocusEntityId();
            const itemIndex = topList?.results?.rows.findIndex(
              (r) => r.id === focusEntityId
            );

            const processedTopList = JSON.parse(
              JSON.stringify(topList)
            ) as TopList;

            if (itemIndex < 4) {
              processedTopList.results.rows =
                processedTopList.results.rows.slice(0, 4);
            } else {
              const top3 = processedTopList.results.rows.slice(0, 3);
              const focusedItem = processedTopList.results.rows[itemIndex];
              processedTopList.results.rows = [...top3, focusedItem];
            }

            return { [type]: processedTopList };
          })
        )
      )
    ).pipe(
      map((results) =>
        results.reduce((acc, result) => ({ ...acc, ...result }), {})
      ),
      shareReplay(1)
    );
  }

  private getStatistics() {
    const filters = this.prepareFilters();

    const actions = Object.values(TOP_LIST_TYPES).map((listType) =>
      widgetActions.fetchTopListsRequest({ listType, filters })
    );

    actions.forEach((action) => this.store.dispatch(action));
  }

  private prepareFilters(): TopListFilters {
    return {
      eaEmployeeId: null,
      eaOfficeId: null,
      timeDateMin: this.minDate,
      timeDateMax: this.maxDate,
      groupBy: this.groupBy,
      focusEntityId: this.getFocusEntityId(),
      limit: 10,
    };
  }

  private initializeDateRange() {
    const currentYear = moment().year();
    const month =
      this.dateType === "month"
        ? (moment().month() + 1).toString().padEnd(2, "0")
        : "01";
    this.minDate = moment(`${currentYear}-${month}-01`).format(
      "YYYYMMDD000000"
    );
    this.maxDate = moment().format("YYYYMMDD235959");
  }

  private getFocusEntityId() {
    return this.groupBy === "employee" ? this.eaEmployeeId : this.eaOfficeId;
  }
}
