import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import { Contact, Employee, Office, Task } from "@app/models";
import { ExternalTip } from "@app/models/external-tips";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalTipsFeature } from "@app/shared/config/models/external-tips";
import { EXTERNAL_TIPS } from "@app/shared/config/utils/features";
import { PhoneFormatPipe } from "@app/shared/modules/ui-components/pipes/phone-format.pipe";
import * as fromUser from "@app/shared/user/user.selectors";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as formUtils from "@app/shared/utils/form-utils";
import { transformObjectToMessageDataSet } from "@app/shared/utils/message-utils";
import { SEND_EXTERNAL_TIPS } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  first,
  map,
  Observable,
  startWith,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import { ConnectableTab } from "../../sidebar-connectable-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { closeTab } from "../../ngrx/sidebar.actions";
import * as fromSidebar from "../../ngrx/sidebar.reducer";
import * as externalTipsActions from "../ngrx/external-tips.actions";
import * as fromExternalTips from "../ngrx/external-tips.reducer";

interface BodyInterface {
  RECEIVERNAME: string;
  SUBJECT: string;
  COMMENT: string;
  CONSUMERNAME: string;
  OFFICENAME: string;
  OFFICEADDRESS: string;
  EMPLOYEEFULLNAME: string;
  EMPLOYEEEMAIL: string;
  EMPLOYEEMOBILE: string;
  fromName: string;
  fromEmail: string;
  consumerFirstName?: string;
  consumerFamilyName?: string;
  consumerEmail?: string;
}

@Component({
  selector: "send-external-tips",
  templateUrl: "./send-external-tips.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./send-external-tips.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendExternalTipsComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  externalTipsForm: FormGroup;
  proxy$ = new Subject<any>();
  tabType = SEND_EXTERNAL_TIPS;
  tab$: Observable<SidebarTab>;
  unsubscribe$ = new Subject<void>();
  contact$: Observable<Contact>;
  user$: Observable<Employee>;
  office$: Observable<Office>;
  customerName$: Observable<string>;
  sendModuleId$: Observable<string>;
  externalTipsFeature$: Observable<ExternalTipsFeature>;
  externalTips$: Observable<ExternalTip[]>;
  isWorking$: Observable<boolean>;
  checkedInput = false;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private phoneFormatPipe: PhoneFormatPipe
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
    this.fetchExternalTips();
    this.connectTab();
    this.registerObserverToFormValueChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.contact$ = this.store.pipe(select(fromContact.getContact));
    this.customerName$ = this.store.pipe(select(fromConfig.getCustomerName));
    this.sendModuleId$ = this.store.pipe(select(fromConfig.getSendModuleId));
    this.user$ = this.store.pipe(select(fromUser.getEmployee));
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.externalTipsFeature$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_TIPS))
    );
    this.externalTips$ = this.store.pipe(
      select(fromExternalTips.getExternalTips)
    );
    this.isWorking$ = this.store.pipe(
      select(fromExternalTips.getLoadingExternalTipsStatus)
    );
  }

  buildForm(): void {
    this.externalTipsForm = this.fb.group({
      consent: [false, Validators.required],
      receiver: ["", Validators.required],
      subject: ["", Validators.required],
      message: ["", Validators.required],
      addToOutbox: true,
    });
  }

  fillForm(): void {
    observableCombineLatest([
      this.contact$,
      this.translateService.get([
        "name",
        "email",
        "mobile",
        "address",
        "tip_from",
      ]),
      this.customerName$,
    ])
      .pipe(
        map(([contact, strings, customerName]) => {
          this.externalTipsForm
            .get("subject")
            .setValue(this.formatSubjectLine(strings.tip_from, customerName));
          this.externalTipsForm
            .get("message")
            .setValue(this.formatMessage(strings, contact));
          this.externalTipsForm.get("addToOutbox").setValue(true);
        }),
        first()
      )
      .subscribe();
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  registerObserverToFormValueChanges(): void {
    this.externalTipsForm.valueChanges
      .pipe(
        map(() => this.externalTipsForm.getRawValue()),
        startWith(this.externalTipsForm.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.proxy$);
  }

  formatSubjectLine(tipFromTranslation: string, name: string): string {
    return tipFromTranslation + " " + name;
  }

  formatMessage(
    translations: {
      name: string;
      email: string;
      mobile: string;
      address: string;
      tip_from: string;
    },
    contact: Contact
  ): string {
    return (
      translations.name +
      ": " +
      contact.getFullName() +
      "\n" +
      translations.email +
      ": " +
      contact.email +
      "\n" +
      translations.mobile +
      ": " +
      this.phoneFormatPipe.transform(contact.msisdn, contact.msisdnCountry) +
      "\n" +
      translations.address +
      ": " +
      contact.getAddress()
    );
  }

  fetchExternalTips(): void {
    this.office$.pipe(first()).subscribe((office) => {
      this.store.dispatch(
        externalTipsActions.getExternalTipsRequest({
          eaOfficeId: office.eaOfficeId,
        })
      );
    });
  }

  onSubmit(): void {
    if (this.externalTipsForm.valid) {
      observableCombineLatest([
        this.sendModuleId$,
        this.contact$,
        this.user$,
        this.office$,
        this.externalTipsFeature$,
      ])
        .pipe(
          map(([moduleId, contact, employee, office, externalTipsFeature]) => {
            const { name, email } = this.externalTipsForm.get("receiver").value;
            const taskBody = this.buildTaskBody(
              contact,
              externalTipsFeature,
              employee,
              office,
              name
            );
            const params = {
              dataSet: transformObjectToMessageDataSet(
                this.buildBody(name, contact, office, employee)
              ),
              recipients: email,
              sendToModule: moduleId,
              templateId: externalTipsFeature.templateId,
              value2: externalTipsFeature.templateId,
            };
            return { params, taskBody };
          }),
          first()
        )
        .subscribe((params) => {
          this.store.dispatch(
            externalTipsActions.sendExternalTipRequest({
              ...params,
              addToOutbox: this.externalTipsForm.get("addToOutbox").value,
            })
          );
        });
    } else {
      formUtils.markAllAsTouched(this.externalTipsForm);
    }
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  private buildBody(
    receiverName: string,
    contact: Contact,
    office: Office,
    employee: Employee
  ): BodyInterface {
    return {
      RECEIVERNAME: receiverName,
      SUBJECT: this.externalTipsForm.controls["subject"].value,
      COMMENT: this.externalTipsForm.controls["message"].value,
      CONSUMERNAME: contact?.getFullName(),
      OFFICENAME: office.officeName,
      OFFICEADDRESS: `${office.street}, ${office.city} ${office.zip}`,
      EMPLOYEEFULLNAME: employee.employeeFullName,
      EMPLOYEEEMAIL: employee.employeeEmail,
      EMPLOYEEMOBILE: employee.employeeMobile,
      fromName: employee.employeeFullName,
      fromEmail: employee.employeeEmail,
      consumerFirstName: contact?.firstName,
      consumerFamilyName: contact?.familyName,
      consumerEmail: contact?.email,
    };
  }

  private buildTaskBody(
    contact: Contact,
    externalTipsFeature: ExternalTipsFeature,
    employee: Employee,
    office: Office,
    receiverEmail: string
  ): Partial<Task> {
    const { name } = this.externalTipsForm.get("receiver")?.value;
    const receiver = !!name ? `(${name})` : "";

    return {
      contactId: contact.contactId,
      eaTaskTypeId: externalTipsFeature.taskTypeId.toString(),
      title: `Tips ${contact.getFullName()} ${receiver}`,
      description: this.externalTipsForm.controls["message"].value,
      eaTaskResultId: externalTipsFeature.taskResultId.toString(),
      startTime: moment(new Date()).format(API_DATE_FORMAT),
      deliveryDate: moment(new Date()).add(3, "days").format(API_DATE_FORMAT),
      originEaEmployeeId: employee.eaEmployeeId,
      originEaOfficeId: office.eaOfficeId,
      param1: receiverEmail,
    };
  }
}
