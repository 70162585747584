<app-q-multi-select
  label="office"
  placeholder="all"
  [options]="options$ | async"
  [ngModel]="value"
  (ngModelChange)="onClick($event)"
  [disabled]="disabled"
  [inline]="inline"
  [atleastOne]="
    !(isAdminOrManager$ | async) && availabilityMode === 'restrictive'
  "
></app-q-multi-select>
