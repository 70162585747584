<article
  *ngIf="lead.title"
  class="search-card search-card-details"
  [routerLink]="getLeadRoute()"
  (click)="this.onClick()"
>
  <section
    class="search-card-details__header search-card-details__header__lead"
  >
    <figure
      class="search-card-details__header__circle search-card-details__header__circle--leads"
    >
      <app-icon name="street-view"></app-icon>
    </figure>
  </section>

  <section class="search-card-details__person-contact-info ellipsify">
    <h4 class="name">
      <i [tooltip]="'lead' | translate" class="fa fa-street-view"></i>
      {{ lead.title }}
    </h4>

    <p *ngIf="lead.typeName"><i class="icon-info"></i>{{ lead.typeName }}</p>
    <p *ngIf="!lead.typeName">
      <i class="icon-info"></i> <span class="muted" translate> - - -</span>
    </p>

    <p *ngIf="lead.insertedDate">
      <i class="icon-calendar"></i>{{ "came_in" | translate }}
      {{ formatDate(lead.insertedDate) | momentify }}
    </p>
    <p *ngIf="!lead.insertedDate">
      <i class="icon-calendar"></i> <span class="muted" translate> - - -</span>
    </p>

    <p *ngIf="lead.employeeFullName">
      <i class="icon-user"></i>{{ "broker" | translate }}:
      {{ lead.employeeFullName }}
    </p>
    <p *ngIf="!lead.employeeFullName">
      <i class="icon-user"></i>{{ "broker" | translate }}:
      <span class="muted" translate> - - -</span>
    </p>

    <p *ngIf="lead.contactFullName">
      <i class="icon-user"></i>{{ "contact" | translate }}:
      {{ lead.contactFullName }}
    </p>
    <p *ngIf="!lead.contactFullName">
      <i class="icon-user"></i>{{ "contact" | translate }}:
      <span class="muted" translate> - - -</span>
    </p>
  </section>
  <app-actions-menu
    *ngIf="!(isMobile$ | async)"
    [dropdownAlignment]="'RIGHT'"
    style="display: flex; align-items: center; margin-right: 0.25rem"
  >
    <ng-container buttons>
      <li role="menuitem">
        <button class="btn btn-default" (click)="openInNewTab($event)">
          <i class="icon-share-alt"></i>
          {{ "open_in_new_tab" | translate }}
        </button>
      </li>
    </ng-container>
  </app-actions-menu>
</article>
