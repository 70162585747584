import { createAction, props } from "@ngrx/store";
import { TopListFilters } from "@app/statistics/statistics-top-lists/models/top-list-filters";
import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";

export const fetchTopListsRequest = createAction(
  "[Widget Summary] FETCH_TOP_LIST_REQUEST",
  props<{ listType: string; filters: TopListFilters }>()
);

export const fetchTopListsSuccess = createAction(
  "[Widget Summary] FETCH_TOP_LIST_SUCCESS",
  props<{ listType: string; summary: boolean; topList: TopList }>()
);

export const fetchTopListsFail = createAction(
  "[Widget Summary] FETCH_TOP_LIST_FAIL",
  props<{ listType: string }>()
);
