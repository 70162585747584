<section class="flex-row" [formGroup]="formGroup">
  <div class="form-group input-container">
    <select formControlName="result">
      <option disabled [ngValue]="''" selected>
        {{ "select_result" | translate }}
      </option>
      <option
        *ngFor="let taskResult of taskResults"
        [ngValue]="taskResult.eaTaskResultId"
      >
        {{ taskResult.resultName }}
      </option>
    </select>
  </div>
  <div class="form-group flex-center">
    <i
      class="icon-question"
      [popover]="popTemplate"
      [popoverTitle]="'result_options' | translate"
      placement="left"
      container="body"
    ></i>
  </div>
</section>

<ng-template #popTemplate>
  <div class="flex-column">
    <div *ngFor="let taskResult of taskResults" class="popover-inner-section">
      <p>
        <span>{{ taskResult.resultName }}</span>
        <br />
        <small>{{ taskResult.resultDescription }}</small>
      </p>
    </div>
  </div>
</ng-template>
