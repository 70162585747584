<div class="q-input">
  <label class="q-input__label">{{ label | translate }}</label>
  <input
    type="text"
    [placeholder]="placeholder | translate"
    class="q-input__control"
    [class.invalid]="invalid"
    [class.disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onTextChange($event)"
    (focus)="markAsTouched()"
    mask="separator"
    thousandSeparator=" "
    inputmode="numeric"
    [disabled]="disabled"
  />
</div>
