<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'add_broker' | translate"
  ></sidebar-header>
  <div class="scroll-view">
    <section>
      <div class="search-label">
        <p>{{ "search_employee_to_add" | translate }}</p>
      </div>
      <div>
        <search-employee
          placeholder="search_broker"
          [ignoreList]="ignoreList$ | async"
          (onSelected)="onSelected($event)"
        ></search-employee>
      </div>
      <div class="flex-center">
        <a [routerLink]="getCreateEmployeeRoute()" class="text-link">{{
          "create_new_broker" | translate
        }}</a>
      </div>
    </section>
  </div>
</div>
