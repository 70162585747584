<span
  id="sm-book-calendar-widget"
  class="text-link"
  *ngIf="!showAsRegularButton; else regularButton"
  (click)="calendarModal.show()"
>
  <span *ngIf="textOnly">{{ "show_calender" | translate }}</span>
  <span
    class="calendar-button"
    *ngIf="!textOnly"
    [tooltip]="'calendar_button_toolip' | translate"
  >
    <app-icon name="calendar-alt"></app-icon>
  </span>
</span>

<ng-template #regularButton>
  <app-q-button
    [icon]="'calendar'"
    [label]="'calendar'"
    [tooltipText]="'calendar_button_toolip'"
    [fullWidth]="true"
    (click)="calendarModal.show()"
  ></app-q-button>
</ng-template>

<div
  bsModal
  class="modal fade"
  #calendarModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: false }"
>
  <div class="modal-dialog">
    <calendar-widget
      (modalCloseClicked)="calendarModal.hide()"
      [showHideModalButton]="true"
      [date]="date"
    >
    </calendar-widget>
  </div>
</div>
