<div
  bsModal
  class="modal fade"
  #modal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static' }"
  (onHidden)="reset()"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">{{ header$ | async | translate }}</h4>
        <i
          *ngIf="descriptionTooltip$ | async as descriptionTooltipText"
          class="icon-question modal__question"
          [tooltip]="descriptionTooltipText | translate"
          placement="bottom"
        ></i>
      </div>
      <div class="modal-body">
        <div [innerHtml]="body$ | async"></div>
        <div class="standard-modal__btn-container">
          <button
            class="btn btn-secondary"
            (click)="goToRoute(link.url)"
            *ngFor="let link of links$ | async"
          >
            {{ link.text }}
          </button>
          <button
            class="btn btn-secondary"
            (click)="openInExternalProvider(link.eaOid)"
            *ngFor="let link of externalLinks$ | async"
          >
            {{ link.text }}
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-cancel btn-lg cancel"
          (click)="close()"
        >
          {{ "cancel_alternative" | translate }}
        </button>
        <button
          *ngIf="confirmButton$ | async as confirmButton"
          type="button"
          class="btn btn-success btn-lg"
          (click)="goToRoute(confirmButton.url)"
        >
          {{ confirmButton.text | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
