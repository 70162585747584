<div class="search-box-container">
  <app-q-search-input
    label="contact"
    placeholder="search_contact_placeholder"
    [invalid]="invalid"
    [data]="searchContactService.quedroContacts$ | async"
    (search)="search($event)"
    (itemClick)="itemClick($event)"
    *ngIf="!value || !showCard"
  >
    <ng-template let-item="item">
      <app-q-search-list-item
        [title]="item.fullName"
        [secondaryText]="[
          item.street,
          phoneNumberService.formatToNational(
            item?.msisdn,
            item?.msisdnCountry
          ),
          item.email
        ]"
      >
      </app-q-search-list-item>
    </ng-template>
  </app-q-search-input>
  <div class="spinner-container">
    <spinner
      *ngIf="searchContactService.getLoading() | async"
      [size]="'1.5rem'"
    ></spinner>
  </div>
</div>
<ng-container *ngIf="value && showCard">
  <app-q-form-group-heading>{{
    "chosen_contact" | translate
  }}</app-q-form-group-heading>
  <app-q-card color="1">
    <ng-container slot="title">
      {{ contact?.firstName }} {{ contact?.familyName }}
    </ng-container>
    <ng-container slot="body">
      <ng-template
        [ngTemplateOutlet]="cardBodyTemplate"
        [ngTemplateOutletContext]="{ contact: contact }"
        *ngIf="cardBodyTemplate; else defaultCardBody"
      ></ng-template>
      <ng-template #defaultCardBody>
        <app-q-card-seconary-text *ngIf="!!contact?.street">
          <app-icon name="map-marker-alt" slot="icon"></app-icon>&nbsp;
          <ng-container slot="text">
            {{ contact?.street || null }}
          </ng-container>
        </app-q-card-seconary-text>
        <app-q-card-seconary-text *ngIf="!!contact?.msisdn">
          <app-icon name="phone-alt" slot="icon"></app-icon>&nbsp;
          <ng-container slot="text">
            {{
              phoneNumberService.formatToNational(
                contact?.msisdn,
                contact?.msisdnCountry
              ) || null
            }}
          </ng-container>
        </app-q-card-seconary-text>
        <app-q-card-seconary-text *ngIf="!!contact?.email">
          <app-icon name="envelope" slot="icon"></app-icon>&nbsp;
          <ng-container slot="text">
            {{ contact?.email || null }}
          </ng-container>
        </app-q-card-seconary-text>
      </ng-template>
    </ng-container>
    <ng-container slot="actions">
      <button type="button" (click)="clear()" *ngIf="!disabled && !readonly">
        <app-icon name="times" size="large"></app-icon>
      </button>
    </ng-container>
  </app-q-card>
</ng-container>
