<div>
  <h4 *ngIf="showTitle" translate>location</h4>
  <ng-container
    class="form-group selected-areas-container"
    *ngIf="(selectedAreas && selectedAreas.length > 0) || !!selectedPolygon"
  >
    <label class="drop-down-title" translate> selected_areas </label>
    <div class="selected-areas-card" *ngFor="let area of selectedAreas">
      <app-q-card color="1">
        <ng-container slot="title">
          {{ area.title }}
        </ng-container>
        <ng-container slot="body">
          <small>{{ area.type | translate }}</small>
          <small *ngIf="area.county">, {{ area.county }}</small>
          <br *ngIf="area.type === 'city_area'" />
          <small
            *ngIf="area.type === 'city_area'"
            (click)="showModal(area)"
            class="show-on-map"
          >
            <span>{{ "show_on_map" | translate }}</span>
          </small>
        </ng-container>
        <ng-container slot="actions">
          <button type="button" (click)="removeArea(area)">
            <app-icon name="times" size="large"></app-icon>
          </button>
        </ng-container>
      </app-q-card>
    </div>
    <div class="selected-areas-card" *ngIf="selectedPolygon">
      <app-q-card color="2">
        <ng-container slot="title">
          {{ "drawn_area" | translate }}
        </ng-container>
        <ng-container slot="actions">
          <button type="button" (click)="removePolygon()">
            <app-icon name="times" size="large"></app-icon>
          </button>
        </ng-container>
      </app-q-card>
    </div>
  </ng-container>
  <div class="dropdown-container">
    <app-q-input
      [type]="'text'"
      [label]=""
      [invalid]="invalid"
      [placeholder]="'location_placeholder'"
      [value]="areaSearchText"
      [ngModel]="areaSearchText"
      (ngModelChange)="getSearchResults($event)"
    ></app-q-input>
    <div class="dropdown-menu-list-container" #dropdown>
      <ng-container *ngIf="customZip">
        <app-q-card
          (click)="
            addArea({ id: customZip, type: areaTypes.ZIP, title: customZip });
            customZip = null
          "
          [class]="'receivers__card-card'"
        >
          <ng-container slot="title">{{ customZip }}</ng-container>
          <ng-container slot="body">
            <small>{{ areaTypes.ZIP | translate }}</small>
          </ng-container>
        </app-q-card>
      </ng-container>
      <ng-container
        *ngIf="
          (areaSearchResults$ | async) &&
          (areaSearchResults$ | async)?.length > 0 &&
          areaSearchText.length > 2
        "
      >
        <div
          *ngFor="let result of areaSearchResults$ | async"
          class="receivers__card"
        >
          <app-q-card
            [class.hidden]="shouldShow(result)"
            (click)="addArea(result)"
          >
            <ng-container slot="title">{{ result.title }}</ng-container>
            <ng-container slot="body">
              <small>{{ result.type | translate }}</small>
              <small *ngIf="result.municipality"
                >, {{ result.municipality }}</small
              >
            </ng-container>
          </app-q-card>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-container
    *ngIf="
      !disableDrawAreaOnMap && (drawSearchProfileAreaFeatureEnabled$ | async)
    "
  >
    <ng-container *ngIf="!selectedPolygon">
      <div (click)="showModal(null)" class="draw-link text-link'">
        {{ "draw_area_on_map" | translate }}
      </div>
    </ng-container>
  </ng-container>
</div>
