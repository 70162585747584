<div class="search">
  <div [ngClass]="overlayActive ? 'search__bar--lg' : ''" class="search__bar">
    <input
      #searchInput
      (click)="onClick()"
      [attr.placeholder]="placeholder"
      [attr.readonly]="readonly"
      [(ngModel)]="value"
    />
    <app-icon type="regular" name="search"></app-icon>
    <spinner
      *ngIf="loading$ | async"
      class="search__spinner pull-right"
      [size]="'1.5rem'"
    >
    </spinner>
  </div>
</div>
