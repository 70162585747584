<div class="widget-header">
  <h3>
    <app-icon size="large" name="user"></app-icon>&nbsp;<span>{{
      "object_parties" | translate
    }}</span>
  </h3>
</div>
<div class="widget-container">
  <app-property-parties-list
    *ngIf="!!eaOid"
    [eaOid]="eaOid"
  ></app-property-parties-list>
</div>
