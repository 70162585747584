<div class="header-flex">
  <h3 translate>top_lists</h3>
  <div class="flex gap-3">
    <div style="width: 120px">
      <app-segmented-controls
        [controls]="groupFilters"
        [active]="groupBy"
        (change)="changeGroupFilter($event)"
      ></app-segmented-controls>
    </div>
    <div style="width: 130px">
      <app-segmented-controls
        [controls]="dateFilters"
        [active]="dateType"
        (change)="changeDateFilter($event)"
      ></app-segmented-controls>
    </div>
  </div>
</div>

<div class="widget-container">
  <div class="widget-container__data">
    <ng-container *ngIf="topLists$ | async as topLists; else noData">
      <ng-container *ngFor="let type of topListKeys">
        <div class="flex-box">
          <app-top-lists-table
            [currency]="currency$ | async"
            [focusEntityId]="groupBy === 'office' ? eaOfficeId : eaEmployeeId"
            [icon]="getTypeIcon(type)"
            [isOffice]="groupBy === 'office'"
            [list]="topLists[type]"
            [loading]="topLists[type]?.loading"
            [title]="getTypeTitle(type)"
          ></app-top-lists-table>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>
