<div class="widget-header">
  <h3>
    <app-icon size="large" name="home"></app-icon>&nbsp;<span>{{
      "details" | translate
    }}</span>
  </h3>
</div>

<div class="widget-container">
  <div *ngIf="showing$ | async as showing" class="overview-data">
    <div class="overview-data--field">
      <span class="text-bold">{{ "object_type" | translate }}:</span>
      <div>
        <span>{{
          showing.objType
            ? (showing.objType | objectTypeName | async)
            : ("object_type_missing" | translate)
        }}</span>
      </div>
    </div>

    <div class="overview-data--field">
      <span class="text-bold">{{ "size" | translate }}:</span>
      <div>
        <span>{{ showing.area }}</span>
        <span translate>area_unit</span>
      </div>
    </div>

    <div class="overview-data--field">
      <span class="text-bold">{{ "rooms" | translate }}:</span>
      <div>
        <span>{{ showing.noOfRooms }}</span>
      </div>
    </div>

    <div class="overview-data--field">
      <span class="text-bold">{{ "rent_month" | translate }}:</span>
      <div>
        <span>
          {{
            isRentZero(showing.rentMonth)
              ? (showing.rentMonth | qNumber) +
                " " +
                showing.priceCode +
                " / " +
                ("month" | translate)
              : ("rent_missing" | translate)
          }}
        </span>
      </div>
    </div>

    <div class="overview-data--field" *ngIf="showing$ | async as showing">
      <span class="text-bold">{{ "responsible_broker" | translate }}:</span>
      <div>
        {{
          showing?.responsibleEmployee?.employeeFullName
            ? showing?.responsibleEmployee?.employeeFullName
            : ("responsible_employee_missing" | translate)
        }}
      </div>
    </div>
  </div>
</div>
