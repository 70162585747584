<div class="wrapper">
  <sidebar-header
    [label]="task.eaTaskTypeName"
    [type]="tabType"
  ></sidebar-header>

  <div class="scroll-view">
    <ng-content select="[card]"></ng-content>
    <hr />
    <section class="flex-column">
      <h4>{{ "select_action" | translate }}</h4>
      <div class="btn-group">
        <button
          (click)="onShowAssign()"
          class="btn btn-lg"
          [class.active]="showAssign$ | async"
          [class.btn-default]="!(showAssign$ | async)"
          [class.btn-primary]="showAssign$ | async"
        >
          {{ "assign" | translate }}
        </button>
        <button
          (click)="onShowManage()"
          class="btn btn-lg"
          [class.active]="!(showAssign$ | async)"
          [class.btn-default]="showAssign$ | async"
          [class.btn-primary]="!(showAssign$ | async)"
          [disabled]="compareTaskWithCurrentUser(task) | async"
        >
          {{ "manage" | translate }}
        </button>
      </div>
    </section>

    <sidebar-tasks-default-assign
      *ngIf="showAssign$ | async; else manage"
      [offices]="offices$ | async"
      [employees]="employees$ | async"
      [formGroup]="form.get('assign')"
      [eaEmployeeId]="eaEmployeeId$ | async"
      [eaTaskId]="task.eaTaskId"
      [isManagerOrAdmin]="isManagerOrAdmin$ | async"
      (assign)="handleAssign($event)"
    ></sidebar-tasks-default-assign>
    <ng-template #manage>
      <sidebar-tasks-default-manage
        [taskResults]="task.taskResults"
        [formGroup]="form.get('manage')"
      ></sidebar-tasks-default-manage>
    </ng-template>
    <hr />
    <section>
      <app-tasks-default-action-log
        [taskActionLogs]="taskActionLogs$ | async"
        [taskType]="task.eaTaskTypeCategory"
      ></app-tasks-default-action-log>
    </section>
  </div>
  <div class="sidebar-footer">
    <task-default-delete-modal
      (deleteClicked)="deleteTask($event)"
      *ngIf="(compareTaskWithCurrentUser(task) | async) === false"
      [headerText]="
        task.eaTaskTypeCategory === 'task'
          ? 'remove_task_message'
          : 'remove_lead_message'
      "
      [task]="task"
      class="float-left"
    >
    </task-default-delete-modal>
    <button
      (click)="onSubmit()"
      [class.icon-spinner]="processing$ | async"
      [disabled]="!form.valid"
      class="btn btn-lg btn-success btn--right"
      type="button"
    >
      <span>{{ "save" | translate }}</span>
    </button>
  </div>
</div>
