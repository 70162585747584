import {
  AdjustedCommissions,
  InvoiceSummary,
  OneTimeFeesData,
  OtherRevenuesDetails,
  PeriodCommissionDetails,
  SubscriptionFeesData,
} from "@app/settings/invoicing/components/invoice-documentation/models/models";
import { InvoicingPeriodResponse } from "@app/statistics/statistics-invoicing/models/invoicing-models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./invoice-documentation.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface InvoiceDocumentationState {
  loadingInvoiceSummary: boolean;
  loadingPeriods: boolean;
  invoiceSummary: InvoiceSummary;
  periods: InvoicingPeriodResponse[];
  loadingPeriodCommissionDetails: boolean;
  periodCommissionDetails: PeriodCommissionDetails;
  loadingOtherRevenuesDetails: boolean;
  otherRevenuesDetails: OtherRevenuesDetails;
  loadingAdjustedCommissions: boolean;
  adjustedCommissions: AdjustedCommissions;
  loadingOneTimeFeesData: boolean;
  oneTimeFeesData: OneTimeFeesData;
  loadingSubscriptionFeesData: boolean;
  subscriptionFeesData: SubscriptionFeesData;
  loadingPeriodCommissionDetailsCSV: boolean;
  loadingOtherRevenuesCSV: boolean;
  loadingAdjustedCommissionsCSV: boolean;
  loadingOneTimeFeesCSV: boolean;
  loadingSubscriptionFeesCSV: boolean;
}

export const initialState: InvoiceDocumentationState = {
  loadingInvoiceSummary: false,
  loadingPeriods: false,
  invoiceSummary: null,
  periods: [],
  loadingPeriodCommissionDetails: false,
  periodCommissionDetails: null,
  loadingOtherRevenuesDetails: false,
  otherRevenuesDetails: null,
  loadingAdjustedCommissions: false,
  adjustedCommissions: null,
  loadingOneTimeFeesData: false,
  oneTimeFeesData: null,
  loadingSubscriptionFeesData: false,
  subscriptionFeesData: null,
  loadingPeriodCommissionDetailsCSV: false,
  loadingOtherRevenuesCSV: false,
  loadingAdjustedCommissionsCSV: false,
  loadingOneTimeFeesCSV: false,
  loadingSubscriptionFeesCSV: false,
};

export function invoiceDocumentationReducer(
  state: InvoiceDocumentationState,
  action: Action
): InvoiceDocumentationState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getInvoiceSummaryRequest, (state) => ({
    ...state,
    loadingInvoiceSummary: true,
    invoiceSummary: null,
  })),
  on(actions.getInvoiceSummarySuccess, (state, { invoiceSummary }) => ({
    ...state,
    loadingInvoiceSummary: false,
    invoiceSummary,
  })),
  on(actions.getInvoiceSummaryFailure, (state) => ({
    ...state,
    loadingInvoiceSummary: false,
    periods: [],
  })),
  on(actions.getInvoicePeriodsRequest, (state) => ({
    ...state,
    loadingPeriods: true,
  })),
  on(actions.getInvoicePeriodsSuccess, (state, { invoicingPeriods }) => ({
    ...state,
    loadingPeriods: false,
    periods: invoicingPeriods,
  })),
  on(actions.getInvoicePeriodsFailure, (state) => ({
    ...state,
    loadingPeriods: false,
  })),
  on(actions.getPeriodCommissionDetailsRequest, (state) => ({
    ...state,
    loadingPeriodCommissionDetails: true,
    periodCommissionDetails: null,
  })),
  on(
    actions.getPeriodCommissionDetailsSuccess,
    (state, { periodCommissionDetails }) => ({
      ...state,
      loadingPeriodCommissionDetails: false,
      periodCommissionDetails,
    })
  ),
  on(actions.getPeriodCommissionDetailsFailure, (state) => ({
    ...state,
    loadingPeriodCommissionDetails: false,
  })),
  on(actions.getPeriodCommissionDetailsCSVRequest, (state) => ({
    ...state,
    loadingPeriodCommissionDetailsCSV: true,
  })),
  on(
    actions.getPeriodCommissionDetailsCSVSuccess,
    actions.getPeriodCommissionDetailsCSVFailure,
    (state) => ({
      ...state,
      loadingPeriodCommissionDetailsCSV: false,
    })
  ),
  on(actions.getOtherRevenuesDetailsRequest, (state) => ({
    ...state,
    loadingOtherRevenuesDetails: true,
    otherRevenuesDetails: null,
  })),
  on(
    actions.getOtherRevenuesDetailsSuccess,
    (state, { otherRevenuesDetails }) => ({
      ...state,
      loadingOtherRevenuesDetails: false,
      otherRevenuesDetails,
    })
  ),
  on(actions.getOtherRevenuesDetailsFailure, (state) => ({
    ...state,
    loadingOtherRevenuesDetails: false,
  })),
  on(actions.getOtherRevenuesDetailsCSVRequest, (state) => ({
    ...state,
    loadingOtherRevenuesCSV: true,
  })),
  on(
    actions.getOtherRevenuesDetailsCSVSuccess,
    actions.getPeriodCommissionDetailsCSVFailure,
    (state) => ({
      ...state,
      loadingOtherRevenuesCSV: false,
    })
  ),
  on(actions.getAdjustedCommissionsRequest, (state) => ({
    ...state,
    loadingAdjustedCommissions: true,
    adjustedCommissions: null,
  })),
  on(
    actions.getAdjustedCommissionsSuccess,
    (state, { adjustedCommissions }) => ({
      ...state,
      loadingAdjustedCommissions: false,
      adjustedCommissions,
    })
  ),
  on(actions.getAdjustedCommissionsFailure, (state) => ({
    ...state,
    loadingAdjustedCommissions: false,
  })),
  on(actions.getAdjustedCommissionsCSVRequest, (state) => ({
    ...state,
    loadingAdjustedCommissionsCSV: true,
  })),
  on(
    actions.getAdjustedCommissionsCSVSuccess,
    actions.getAdjustedCommissionsCSVFailure,
    (state) => ({
      ...state,
      loadingAdjustedCommissionsCSV: false,
    })
  ),
  on(actions.getOneTimeFeesDataRequest, (state) => ({
    ...state,
    loadingOneTimeFeesData: true,
    neTimeFeesData: null,
  })),
  on(actions.getOneTimeFeesDataSuccess, (state, { oneTimeFeesData }) => ({
    ...state,
    loadingOneTimeFeesData: false,
    oneTimeFeesData,
  })),
  on(actions.getOneTimeFeesDataFailure, (state) => ({
    ...state,
    loadingOneTimeFeesData: false,
  })),
  on(actions.getOneTimeFeesCSVRequest, (state) => ({
    ...state,
    loadingOneTimeFeesCSV: true,
  })),
  on(
    actions.getOneTimeFeesCSVSuccess,
    actions.getOneTimeFeesCSVFailure,
    (state) => ({
      ...state,
      loadingOneTimeFeesCSV: false,
    })
  ),
  on(actions.getSubscriptionFeesDataRequest, (state) => ({
    ...state,
    loadingSubscriptionFeesData: true,
    subscriptionFeesData: null,
  })),
  on(
    actions.getSubscriptionFeesDataSuccess,
    (state, { subscriptionFeesData }) => ({
      ...state,
      loadingSubscriptionFeesData: false,
      subscriptionFeesData,
    })
  ),
  on(actions.getSubscriptionFeesDataFailure, (state) => ({
    ...state,
    loadingSubscriptionFeesData: false,
  })),
  on(actions.getSubscriptionFeesCSVRequest, (state) => ({
    ...state,
    loadingSubscriptionFeesCSV: true,
  })),
  on(
    actions.getSubscriptionFeesCSVSuccess,
    actions.getSubscriptionFeesCSVFailure,
    (state) => ({
      ...state,
      loadingSubscriptionFeesCSV: false,
    })
  ),
  on(actions.resetState, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.invoiceDocumentation
);

export const getInvoiceSummaryLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingInvoiceSummary
);

export const getInvoiceSummary = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.invoiceSummary
);

export const getInvoicePeriodsLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingPeriods
);

export const getInvoicePeriods = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.periods
);

export const getPeriodCommissionDetailsLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingPeriodCommissionDetails
);

export const getPeriodCommissionDetails = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.periodCommissionDetails
);

export const getOtherRevenuesDetailsLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingOtherRevenuesDetails
);

export const getOtherRevenuesDetails = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.otherRevenuesDetails
);

export const getAdjustedCommissionsLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingAdjustedCommissions
);

export const getAdjustedCommissions = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.adjustedCommissions
);

export const getOneTimeFeesDataLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingOneTimeFeesData
);

export const getOneTimeFeesData = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.oneTimeFeesData
);

export const getSubscriptionFeesDataLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingSubscriptionFeesData
);

export const getSubscriptionFeesData = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.subscriptionFeesData
);

export const getPeriodCommissionDetailsCSVLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingPeriodCommissionDetailsCSV
);

export const getOtherRevenuesDetailsCSVLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingOtherRevenuesCSV
);

export const getAdjustedCommissionsCSVLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingAdjustedCommissionsCSV
);

export const getOneTimeFeesCSVLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingOneTimeFeesCSV
);

export const getSubscriptionFeesCSVLoading = createSelector(
  getState,
  (state: InvoiceDocumentationState) => state.loadingSubscriptionFeesCSV
);
