<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'create_message_template' | translate"
  >
  </sidebar-header>
  <div class="scroll-view">
    <app-message-templates-form action="create"> </app-message-templates-form>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button buttonType="save" label="save" (click)="onSubmit()">
    </app-q-button>
  </div>
</div>
