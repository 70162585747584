import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { filter, first } from "rxjs";
import { AppState } from "@app/app.reducer";
import {
  checkIfSystemOnlineRequest,
  loadRequest,
} from "@app/shared/config/config.actions";
import { getProxyVerified } from "@app/shared/config/config.reducer";
import * as routerActions from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-system-temporarily-down",
  templateUrl: "./system-temporarily-down.component.html",
  styleUrls: ["./system-temporarily-down.component.scss"],
})
export class SystemTemporarilyDownComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    let numberOfTry = 0;
    const systemCheckInterval = setInterval(() => {
      if (numberOfTry >= 10) {
        clearInterval(systemCheckInterval);
        return;
      }
      numberOfTry++;
      this.checkIfSystemOnline();
    }, 5000);
    this.store
      .pipe(
        select(getProxyVerified),
        filter((verified) => !!verified),
        first()
      )
      .subscribe(() => {
        clearInterval(systemCheckInterval);
        this.store.dispatch(loadRequest());
        this.store.dispatch(routerActions.go({ path: "crm" }));
      });
  }

  checkIfSystemOnline(): void {
    this.store.dispatch(checkIfSystemOnlineRequest());
  }
}
