<app-q-modal>
  <ng-container slot="header">
    <div class="header" *ngIf="contact$ | async as contact; else loadingSmall">
      <span [class]="contact.getIconClass()" class="mr-05">
        <app-icon size="none" [name]="contact.getIcon()"></app-icon>
      </span>
      {{ contact.getFullName() }}
      <span class="ml-05">
        <app-contact-quick-actions
          [dialogRef]="dialogRef"
          [contact]="contact$ | async"
        ></app-contact-quick-actions>
      </span>
    </div>
  </ng-container>
  <ng-container slot="body">
    <div class="modal-body">
      <section
        class="body-row"
        *ngIf="
          !(isLoading$ | async) && !!(contact$ | async);
          else loadingMedium
        "
      >
        <contact-summary-info
          [contact$]="contact$"
          [isLoading]="isLoading$ | async"
          (contactLinkClicked)="addBackLink(); closeModal()"
        ></contact-summary-info>
        <div class="actions">
          <app-q-button
            (click)="goToContact()"
            [fullWidth]="true"
            class="action"
            [label]="'go_to_contact'"
            [buttonType]="'save'"
            [icon]="'arrow-right'"
            [enableHoverMenu]="true"
          >
            <div menuContent>
              <div *ngFor="let subPage of subPagesService.subPages$ | async">
                <app-q-action-dropdown-item
                  [label]="subPage.label"
                  [icon]="subPage.icon"
                  (press)="goToContact(subPage.routerLink.slice(-1).pop())"
                ></app-q-action-dropdown-item>
              </div>
            </div>
          </app-q-button>
          <app-q-button
            (click)="openInNewTab()"
            [fullWidth]="true"
            class="action"
            [label]="'open_in_new_tab'"
            [buttonType]="'save'"
            [icon]="'share-square'"
            [enableHoverMenu]="true"
          >
            <div menuContent>
              <div *ngFor="let subPage of subPagesService.subPages$ | async">
                <app-q-action-dropdown-item
                  [label]="subPage.label"
                  [icon]="subPage.icon"
                  (press)="openInNewTab(subPage.routerLink.slice(-1).pop())"
                ></app-q-action-dropdown-item>
              </div>
            </div>
          </app-q-button>
        </div>
      </section>
      <hr />
      <section
        *ngIf="
          !(isLoading$ | async) && !!(contact$ | async);
          else loadingMedium
        "
      >
        <contact-summary-consents
          [contact$]="contact$"
          [isLoading]="isLoading$ | async"
          [consents$]="consents$"
        ></contact-summary-consents>
      </section>
      <hr />
      <section class="modal-body__services">
        <div class="item">
          <ng-container
            *ngIf="
              !(isLoading$ | async) && !!(contact$ | async);
              else loadingLarge
            "
          >
            <contact-summary-services
              [contact$]="contact$"
              [isLoading]="isLoading$ | async"
              [numberOfTasks$]="numberOfTasks$"
              [numberOfObjects$]="numberOfObjects$"
              [hasPriceUpdateOrder$]="hasPriceUpdateOrder$"
              [hasAlert$]="hasAlert$"
              [salesMeetings$]="salesMeetings$"
              [ownsResidence$]="ownsResidence$"
            ></contact-summary-services>
          </ng-container>
        </div>
        <div class="item">
          <ng-container
            *ngIf="
              !(isLoading$ | async) && !!(contact$ | async);
              else loadingLarge
            "
          >
            <contact-summary-notes
              [contact$]="contact$"
              [notes$]="notes$"
              [isLoading]="isLoading$ | async"
            ></contact-summary-notes>
          </ng-container>
        </div>
      </section>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <button class="q-btn--cancel" mat-dialog-close (click)="closeModal()">
      {{ "close" | translate | uppercase }}
    </button>
  </ng-container>
</app-q-modal>

<ng-template #loadingSmall>
  <skeleton-rectangle [height]="1"></skeleton-rectangle>
</ng-template>
<ng-template #loadingMedium>
  <skeleton-rectangle [height]="3"></skeleton-rectangle>
</ng-template>
<ng-template #loadingLarge>
  <skeleton-rectangle [height]="4"></skeleton-rectangle>
</ng-template>
