<ngui-map
  style="height: 1px; width: 1px"
  (mapReady$)="onMapReady($event)"
></ngui-map>
<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{ "draw_area_on_map" | translate }}
  </h4>
  <ng-container *ngIf="showMode !== 'Create'">
    <h4 class="modal-title pull-right">
      {{ areaToShow?.title }} - {{ areaToShow?.id }}
    </h4>
  </ng-container>
</div>

<div class="modal-body">
  <div *ngIf="showMode === 'Create'" class="form-group">
    <input
      [(ngModel)]="title"
      type="text"
      placeholder="{{ 'title' | translate }} *"
      required
      [class.invalid]="!title || title?.trim().length === 0"
    />
  </div>
  <div id="map-container"></div>
</div>

<div class="modal-footer">
  <div>
    <app-q-button
      buttonType="cancel"
      label="cancel"
      classes="margin-right"
      (click)="hide()"
    >
    </app-q-button>
    <app-q-button
      *ngIf="!areaToShow && showMode === 'Create'"
      buttonType="cancel"
      label="clear_map_areas"
      (click)="clearPolygons()"
    >
    </app-q-button>
  </div>
  <div class="success-btn-container">
    <div
      *ngIf="feature === 'search-profile' && showMode === 'Create'"
      class="checkbox"
    >
      <label for="permanent">
        <input [(ngModel)]="saveAsPermanent" id="permanent" type="checkbox" />
        <span translate>save_as_permanent_area</span>
      </label>
    </div>
    <app-q-button
      *ngIf="showMode === 'Create'"
      [disabled]="
        polygonsToBeSaved.length === 0 || storingPolygon || title.length == 0
      "
      [isLoading]="storingPolygon"
      buttonType="save"
      label="save"
      (click)="onSubmit()"
    >
    </app-q-button>
    <app-q-button
      *ngIf="showMode === 'Filter'"
      buttonType="save"
      label="set"
      (click)="onSubmit()"
    >
    </app-q-button>
  </div>
</div>
