<app-q-modal>
  <ng-container slot="header">
    {{
      "send_as_potential_buyer_to_erp"
        | translate : { erp: externalProviderName$ | async | titlecase }
    }}
  </ng-container>
  <ng-container slot="body">
    <form [formGroup]="form" class="form-container">
      <div class="group">
        <div>
          <app-q-search-contact
            formControlName="contactId"
            [invalid]="
              form.get('contactId').touched && !form.get('contactId').valid
            "
            [readonly]="false"
            [showCard]="!canSelectMultiple"
            (contactSelectedChange)="handleSelectedContact($event)"
          ></app-q-search-contact>
          <app-q-control-errors
            name="contactId"
            [errors]="[
              { name: 'required', text: 'validation_required', when: 'touched' }
            ]"
          >
          </app-q-control-errors>
        </div>
        <div>
          <app-q-search-object
            formControlName="eaOid"
            [invalid]="form.get('eaOid').touched && !form.get('eaOid').valid"
            [readonly]="false"
          >
          </app-q-search-object>
          <app-q-control-errors
            name="eaOid"
            [errors]="[
              { name: 'required', text: 'validation_required', when: 'touched' }
            ]"
          >
          </app-q-control-errors>
        </div>
      </div>
      <ng-container
        *ngIf="canSelectMultiple && (selectedContacts$ | async).length > 0"
      >
        <app-q-form-group-heading>{{
          "chosen_contact" | translate
        }}</app-q-form-group-heading>
        <div class="contact-cards-container">
          <div *ngFor="let contact of selectedContacts$ | async">
            <app-q-card color="1">
              <ng-container slot="title">
                {{ contact?.firstName }} {{ contact?.familyName }}
              </ng-container>
              <ng-container slot="body">
                <app-q-card-seconary-text *ngIf="!!contact?.street">
                  <app-icon name="map-marker-alt" slot="icon"></app-icon>&nbsp;
                  <ng-container slot="text">
                    {{ contact?.street || null }}
                  </ng-container>
                </app-q-card-seconary-text>
                <app-q-card-seconary-text *ngIf="!!contact?.msisdn">
                  <app-icon name="phone-alt" slot="icon"></app-icon>&nbsp;
                  <ng-container slot="text">
                    {{
                      phoneNumberService.formatToNational(
                        contact?.msisdn,
                        contact?.msisdnCountry
                      ) || null
                    }}
                  </ng-container>
                </app-q-card-seconary-text>
                <app-q-card-seconary-text *ngIf="!!contact?.email">
                  <app-icon name="envelope" slot="icon"></app-icon>&nbsp;
                  <ng-container slot="text">
                    {{ contact?.email || null }}
                  </ng-container>
                </app-q-card-seconary-text>
              </ng-container>
              <ng-container slot="actions">
                <button
                  type="button"
                  (click)="removeSelectedContact(contact.contactId)"
                >
                  <app-icon name="times" size="large"></app-icon>
                </button>
              </ng-container>
            </app-q-card>
          </div>
        </div>
      </ng-container>
    </form>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button
        mat-dialog-close
        (click)="closeModal()"
        buttonType="cancel"
        label="close"
        classes="margin-right"
      >
      </app-q-button>
      <app-q-button
        (click)="submit()"
        label="{{
          'save_and_send_to_erp'
            | translate : { erp: externalProviderName$ | async | titlecase }
        }}"
        buttonType="save"
        [isLoading]="isWorking$ | async"
        [disabled]="isWorking$ | async"
      >
      </app-q-button>
    </div>
  </ng-container>
</app-q-modal>
